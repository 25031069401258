
import Vue, { PropType } from 'vue'
export default Vue.extend({
    name: "BaseExpanderItem",
    props: {
        title: {
            type: String,
            required: true,
        },
            startOpen: {
            type: Boolean,
            required: false,
            default: true,
        },
        type: {
            type: String as PropType<"Base" | "Simple">,
            required: false,
            default: "Base",
        },
    },
    data: function () {
        return {
            isOpen: true,
            containerHeight: 1000,
        };
    },
    computed: {
        openSymbol: function (): string {
            if (this.isOpen) {
                return "expand_less";
            } else {
                return "expand_more";
            }
        },
        styleCssVars: function (): object {
            return {
                "--height-expander-content": `${this.containerHeight}px`,
            };
        },
    },
    created: function () {
        if (this.startOpen) {
            this.isOpen = true;
        } else {
            this.isOpen = false;
        }
    },
    methods: {
        afterEnter: function () {
            let container = this.$refs.content;
            if (container) {
                this.containerHeight = (container as HTMLElement).clientHeight;
            }
            this.$emit("opened");
        },
        afterLeave: function () {
            this.containerHeight += 50;
            this.$emit("closed");
        },
    },
})
