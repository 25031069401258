
import Vue from "vue";
export default Vue.extend({
  name: "SeatControlInput",
  components: {},
  props: {
    seatNumber: {
      type: Number,
      required: true,
    },
    seatAssignment: {
      validator: (prop) => typeof prop === "string" || prop === null,
      required: false,
      default: null,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: true,
    },
    enabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data: function () {
    return {
      currentStateValid: false,
      currentStateText: "",
    };
  },
  computed: {
    isValid: function (): boolean {
      return this.validateEmailValue(this.seatAssignment) === true ? true : false;
    },
    isAssigned: function (): boolean {
      return this.seatAssignment != null && this.seatAssignment !== "";
    },
    hintToConfirm: function(): string | null {
      if (this.currentStateText !== "" && this.currentStateValid) {
        return `Assign this email to Seat ${this.seatNumber + 1}?`
      } else {
        return null;
      }
    }
  },
  methods: {
    validateEmailValue: function (
      value: string | null | undefined,
      acceptEmpty: boolean = true
    ): boolean | string {
      // allow clearing of input
      if (value == null || value === "") {
        return acceptEmpty ? true : false;
      }

      // otherwise account for string
      // got this regex pattern from vuetify docs:
      // https://vuetifyjs.com/en/components/text-fields/#validation
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) ? true : "Invalid Email Address";
    },
    validateCurrentInputState: function (currentString: string) {
      let valid = this.validateEmailValue(currentString, false);
      this.currentStateValid = valid === true ? true : false;
      this.currentStateText = currentString;
    },
    emitEmailInputChanged: function () {
      let valid = this.validateEmailValue(this.currentStateText);
      if (valid === true) {
        this.$emit("seatAssignmentChanged", this.seatNumber, this.currentStateText);
      }
    },
    clearEmail: function () {
      this.$emit("seatAssignmentChanged", this.seatNumber, "");
    },
    clearInput: function() {
      if (this.$refs.input) {
        (this.$refs.input as any).reset();
      }
    },
    handleKeydown: function(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        this.emitEmailInputChanged();
      }

      if (event.key === 'Escape') {
        this.clearInput();
      } 
    }
  },
});
