
import { ProductTeam } from "@/modules/API";
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import ExpanderEl from "./elComponents/ExpanderEl.vue";
import ProductTeamControl from "./product-team-control/ProductTeamControl.vue";
export default Vue.extend({
  name: "TheTeamsContainer",
  components: { ExpanderEl, ProductTeamControl },
  props: {},
  data: function () {
    return {};
  },
  computed: {
    ...mapState(["CurrentProductTeams", "LoadingProductTeams", "ErrorAlertMessage"]),
    /** returns ProductTeam that are not Helix or Glyph */
    otherTeams: function (): ProductTeam | null {
      return this.CurrentProductTeams.filter(
        (t: ProductTeam) => !["Helix", "Glyph", "Veras", "Morphis"].includes(t.product_name)
      );
    },
    /** returns ProductTeam for Helix if exists or null if not */
    helixTeam: function (): ProductTeam | null {
      let helix = this.CurrentProductTeams.filter(
        (t: ProductTeam) => t.product_name === "Helix"
      );
      return helix.length > 0 ? helix[0] : null;
    },
    /** If no Helix, will give user the option to purchase Helix */
    hasHelix: function (): boolean {
      return this.helixTeam != null;
    },
    /** returns ProductTeam for Glyph if exists or null if not */
    glyphTeam: function (): ProductTeam | null {
      let glyph = this.CurrentProductTeams.filter(
        (t: ProductTeam) => t.product_name === "Glyph"
      );
      return glyph.length > 0 ? glyph[0] : null;
    },
    /** If no Glpyh, will give the user the option to purchase Glyph */
    hasGlyph: function (): boolean {
      return this.glyphTeam != null;
    },
    /** returns ProductTeam for Veras if exists or null if not */
    verasTeam: function (): ProductTeam | null {
      let veras = this.CurrentProductTeams.filter(
        (t: ProductTeam) => t.product_name === "Veras"
      );
      return veras.length > 0 ? veras[0] : null;
    },
    /** If no Veras, will give the user the option to purchase Veras */
    hasVeras: function (): boolean {
      return this.verasTeam != null;
    },
    /** returns ProductTeam for Morphis if exists or null if not */
    morphisTeam: function (): ProductTeam | null {
      let morphis = this.CurrentProductTeams.filter(
        (t: ProductTeam) => t.product_name === "Morphis"
      );
      return morphis.length > 0 ? morphis[0] : null;
    },
    /** If no Morphis, will give the user the option to purchase Morphis */
    hasMorphis: function (): boolean {
      return this.morphisTeam != null;
    },
  },
  mounted: async function () {
    this.GetUsersProductTeams();
  },
  methods: {
    ...mapActions(["GetUsersProductTeams", "CreatePutProductTeam"]),
    /** Called on click to send user to page to purchase Helix */
    purchaseHelix: function () {
      window.location.href = 'https://www.evolvelab.io/helix';
    },
    /** Called on click to send user to page to purchase Glyph */
    purchaseGlyph: function () {
      window.location.href = 'https://www.evolvelab.io/glyph';
    },
    /** Called on click to send user to page to purchase Veras */
    purchaseVeras: function () {
      window.location.href = 'https://www.evolvelab.io/veras';
    },
    /** Called on click to send user to page to purchase Morphis */
    purchaseMorphis: function () {
      window.location.href = 'https://www.evolvelab.io/morphis';
    },
  },
});
