
import { ProductTeam } from "@/modules/API";
import { cloneDeep } from "lodash";
import Vue, { PropType } from "vue";
import { mapActions } from "vuex";
import SeatControlInput from "./SeatControlInput.vue";
export default Vue.extend({
  name: "ProductTeamControl",
  components: { SeatControlInput },
  props: {
    currentTeam: {
      type: Object as PropType<ProductTeam>,
      required: true,
    },
  },
  data: function () {
    return {
      showAll: false,
    };
  },
  computed: {
    licencesRemaining: function (): number {
      return this.currentTeam.number_of_seats - this.currentTeam.members.length;
    },
    /** will return true if there are more members assigned than number of seats bought */
    isOverAssigned: function (): boolean {
      return this.currentTeam.members.length > this.currentTeam.number_of_seats;
    },
    licenseArray: function (): { seatNumber: number; assignedTo: string | null }[] {
      if (this.currentTeam == null) {
        return [];
      }

      let licenseArray = [] as {
        seatNumber: number;
        assignedTo: string | null;
        readonly: boolean;
        enabled: boolean;
      }[];
      for (let i = 0; i < this.currentTeam.number_of_seats; i++) {
        const enabled = i <= this.currentTeam.members.length;
        // only show 1 extra license
        if (!enabled && !this.showAll) {
          break;
        }
        // push license to array
        licenseArray.push({
          seatNumber: i,
          assignedTo: this.currentTeam.members[i],
          readonly: this.currentTeam.members[i] != null,
          enabled: enabled,
        });
      }

      return licenseArray;
    },
  },
  methods: {
    ...mapActions(["CreatePutProductTeam"]),
    handleSeatAssignmentChanged: function (
      seatNumber: number,
      seatAssignment: string | null | undefined
    ) {
      let updatedTeam = cloneDeep(this.currentTeam);
      // if this seatNumber was set previously, update to new seat assignment
      if (updatedTeam.members[seatNumber]) {
        // ensure that if null or undefined, just set to blank string
        updatedTeam.members[seatNumber] = seatAssignment ? seatAssignment : "";
      }
      // else if it was not, add it to the list
      // the ui will prevent adding seats out of order so should not be an issue
      else {
        if (seatAssignment != null && seatAssignment !== "") {
          updatedTeam.members.push(seatAssignment);
        }
      }

      // then we filter out blank strings
      // and send to the api
      updatedTeam.members = updatedTeam.members.filter((m) => m !== "");
      this.CreatePutProductTeam(updatedTeam);
    },
  },
});
