var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"w-full flex flex-col flex-shrink-0",class:{
    'bg-offwhite rounded border border-solid border-gray-light shadow object-contain overflow-hidden flex-shrink-0':
      _vm.type === 'Base',
  }},[_c('article',{staticClass:"flex w-full items-center cursor-pointer",class:{
      'justify-between p-3': _vm.type === 'Base',
      'space-x-1 pb-1': _vm.type === 'Simple',
    },on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[(_vm.type ==='Simple')?_c('div',{staticClass:"flex -translate-y-1 cursor-pointer",class:{'triangle-down': _vm.isOpen, 'triangle-up': !_vm.isOpen},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}}):_vm._e(),_c('h1',{staticClass:"flex cursor-pointer",class:{
        'text-black flex-grow-0 text-xl font-medium font-title pl-2': _vm.type === 'Base',
        'text-black text-xs font-light':
          _vm.type === 'Simple',
      }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('button',{staticClass:"z-10 hover:scale-125"},[(_vm.type === 'Base')?_c('div',{staticClass:"flex material-icons-outlined text-black flex-grow-0 font-medium cursor-pointer select-none"},[_vm._v(" "+_vm._s(_vm.openSymbol)+" ")]):_vm._e()])]),_c('transition',{attrs:{"name":"drawer"},on:{"after-enter":_vm.afterEnter,"after-leave":_vm.afterLeave}},[_c('article',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"content",staticClass:"flex w-full overflow-hidden object-contain",class:{
        'bg-offwhite ': _vm.type === 'Base',
        '': _vm.type === 'Simple',
      },style:(_vm.styleCssVars)},[_vm._t("default",function(){return [_vm._v("Placeholder")]})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }